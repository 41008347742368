/* At last you have to copy these css styles from here ,Create a CSS file and paste it and import the file */

/* Also you can customize these css styles*/

/* In my case, I use these CSS styles in my App.css file*/
.listing-section{
  background-color: var(--color-blue);
  padding: 2rem;
}
.listing .swiper {
    width: 80%;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  
  /* Swiper wrapper */
   .listing .mySwiper2{
    max-height:70vh;
   }

   .listing .mySwiper{
    min-height:10vh;
   }
   
   
  .listing .swiper-slide {
    background-position: center;
    background-size: cover;
    display:flex;
    justify-content: center;
  }
  
  .listing .swiper-slide img {
    display: cover;
    max-width: 100%;
    max-height: 70vh;
  }