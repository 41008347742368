/* At last you have to copy these css styles from here ,Create a CSS file and paste it and import the file */

/* Also you can customize these css styles*/

/* In my case, I use these CSS styles in my App.css file*/

.listinghero .swiper-container {
  object-fit: contain; 
  width: 100%;
  height: 80vh;  
  /* position: absolute; */
  z-index: -1;
}
.listinghero .swiper {
    width: 80%;
    /* padding-top: 50px;
    padding-bottom: 50px; */

  }
  
  /* Swiper wrapper */
  

   .listinghero .mySwiper{
    min-height:10vh;
   }
   
   
  .listinghero .swiper-slide {
    background-position: center;
    background-size: cover;
    display:flex;
    justify-content: center;
  }
  
  .listinghero .swiper-slide img {
    display: cover;
    /* width: 100%; */
    height: 100vh;
  }