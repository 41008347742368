@import url(//fonts.googleapis.com/css?family=Montserrat:300,400,500,700);
.testimonial10 {
  font-family: "Montserrat", sans-serif;
  color: #8d97ad;
  font-weight: 300;
}

.testimonial10 h1,
.testimonial10 h2,
.testimonial10 h3,
.testimonial10 h4,
.testimonial10 h5,
.testimonial10 h6 {
  color: #3e4555;
  text-align: center;
}

.testimonial10 .font-weight-medium {
  font-weight: 500;
}

.testimonial10 .subtitle {
  color: #8d97ad;
  line-height: 24px;
}

.testimonial10 .testi10 .quote-bg {
  max-width: 800px;
  width: 100%;
  margin: 0 auto;
  padding: 60px;
  background: url(https://www.wrappixel.com/demos/ui-kit/wrapkit/assets/images/testimonial/up-quote.png) no-repeat top left, url(https://www.wrappixel.com/demos/ui-kit/wrapkit/assets/images/testimonial/down-quote.png) no-repeat bottom right;
}

.testimonial10 .testi10 h3 {
  line-height: 40px;
    font-size: 24px;
}

.testimonial10 .testi10 .customer-thumb img {
    width: 60px;
	margin-left: auto;
	margin-right: auto;
}

@media (max-width: 767px) {
  .testimonial10 .testi10 h3 {
    line-height: 30px;
    font-size: 20px;
  }
}

.testimonial10.bg-success-gradiant {
    /* background: #ffffff; */
    /* background: -webkit-linear-gradient(legacy-direction(to right), #2cdd9b 0%, #1dc8cc 100%); */
    /* background: -webkit-gradient(linear, left top, right top, from(#2cdd9b), to(#1dc8cc)); */
    /* background: -webkit-linear-gradient(left, #8cc5df 0%, #bc9de0 100%); */
    /* background: -o-linear-gradient(left, #367a61 0%, #1dc8cc 100%); */
    background: linear-gradient(to top, var(--color-blue) 0%,  #ffffff 100%);
}