.app__about {
    position: relative;
    background-color: #FFF;
    /* height: 100vh; */
}

.app__about-overlay {
    position: absolute;
    inset: 0 0 20%;
}

.app__about-content {
    display: flex;
    width: 100%;
    z-index: 2;
    gap: 2rem;
    /* height: 100%; */
}

.app__about-content_about {
    display: flex;

    flex: 1;

     /* The image used */
    background-image: url('/images/woman.jpg');

    /* Set a specific height */
    min-height: 50rem;
    min-width: 20rem;
    /* width: 50%; */

    /* Create the parallax scrolling effect */
    background-attachment: fixed;
    /* background-position: center; */
    background-repeat: no-repeat;
    background-size: contain;
}

.app__about-content_history {
    flex: 1;
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    flex-direction: column;

    text-align: left;
}

.app__about-content_history img{
    max-width: 40vw;
}

.app__about-content_about p,
.app__about-content_history p {
    margin: 2rem 0;
    color: var(--color-darkblue);
}

.app__about-highlight{
    flex-direction: column;
    height: 100%;
}

.app__about-highlight img {
    object-fit: fill;
}
.highlightList{
    display: grid;
    margin: 3rem;
    grid-template-columns: 5rem 5rem;
    grid-gap: 1rem;
    justify-content: space-around;
    text-align: center;
}

.highlightList_features{
    display: flexbox;
    margin: 3rem;
    grid-template-columns: 10rem 10rem 10rem 10rem;
    grid-gap: 1rem;
    justify-content: space-around;
    text-align: center;
}

.highlightList_features .CardItem {
    background-color: black;
}

.app__charac p{
    min-width: 20vw;
}

.app__features{
    flex-direction: column;
}

.Youtube{
    padding-top: 5rem;
    min-width: 80vw;
    justify-content: center;
}

.flex__center .vertical__margin{
    justify-content:space-between;
}
.self_align{
    align-self:center;
}
   
   /* Vertical  margin */
.app__about-highlight .flex__center .app__about-content_history .vertical__margin{
    width:100%;

}

   .app__charac__highlight{
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr))
   }

#characteristic div img{
overflow:auto;
min-width:50vw;
}


@media screen and (min-width: 2000px) {
    .app__about-content_about p,
    .app__about-content_history p {
        margin: 4rem 0;
    }
}

@media screen and (max-width: 900px) {
    .app__about-content {
        flex-direction: column;
    }

    .app__about-content_knife {
        margin: 4rem 0;
    }

    .app__about-highlight img {
        max-width: 90vw;
        object-fit: fill;
    }

    .app__charac{
        flex-direction: column;
        width: 100%;
    }
}

@media screen and (max-width: 650px) {
    .section__padding {
        padding: 2rem;
    }

}