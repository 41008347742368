/* At last you have to copy these css styles from here ,Create a CSS file and paste it and import the file */

/* Also you can customize these css styles*/

/* In my case, I use these CSS styles in my App.css file*/
.floorplan .swiper {
    max-height: 100%;
    min-width: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  
  /* Swiper wrapper */
.floorplan .mySwiper2{
    max-height:70vh;
   }

.floorplan .mySwiper{
    min-height:10vh;
   }
   
.floorplan .swiper-slide {
    background-position: center;
    background-size: cover;
    display:flex;
    justify-content: center;
  }

  .swiper .swiper-slide {
    background-position: center;
    background-size: cover;
    display:flex;
    justify-content: center;
    height: 20vh;
  }

.floorplan .swiper-slide img {
    max-height: 70vh;
    display: cover;
    /* width: 100%; */
  }

  .floorplan >h1{
    color: white;
  }

  .swiper .swiper-slide img {
    cursor: pointer;
    display: fill;
    overflow: hidden;
    object-fit: cover;
  }