.menuitem {
    overflow: hidden;
    box-shadow: 0px 2px 5px rgb(155, 155, 155);
    /* border-radius: 25px; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
    transition: transform 200ms ease-in;
    height: 30vh;
    max-width: 50vh;
    min-width: 30vw;
    /* z-index: 1; */
    position: relative;
    margin: 0.1rem;
    /* background: radial-gradient(at center center, #2d7e9270 0%, #1c40617c 100%); */
}
.card:hover {
    transform: scale(1.05);
    transition: transform 100ms ease-in;
} 
.menuitem__img {
    display: flex;
    flex: 1;
    /* background: radial-gradient(at center center, #2d7e9270 0%, #1c40617c 100%); */
    height: 100%;
    width: 45vw;
    object-fit: cover;
    /* z-index: -1; */
    /* opacity: .8; */
    position: absolute;
    top: -9999px;
    bottom: -9999px;
    left: -9999px;
    right: -9999px;
    margin: auto;
}
.menuitem__img:hover {
    transform: scale(1.1);
    transition: all 0.2s linear;

}
  
.card__propertyType {
    text-transform: uppercase;
    padding: 1rem;
    /* font-size: 30px; */
    color: white;
    text-align: justify;
    position: absolute;
    top: 0;
    bottom: 1;
    left: 0;
    right: 0;
    height: fit-content;
    margin: auto;
    /* text-shadow: -1px 0 var(--color-golden), 0 1px var(--color-golden), 1px 0 var(--color-golden), 0 -1px var(--color-golden); */
}
.card__city {
    text-transform: uppercase;
    padding: 1rem;
    font-size: 2rem;
    /* color: var(--color-black); */
    text-align: center;
    position: absolute;
    top: 20vh;
    bottom: 0;
    left: 0;
    right: 0;
    height: fit-content;
    margin: auto;
    /* text-shadow: -1px 0 var(--color-golden), 0 1px var(--color-golden), 1px 0 var(--color-golden), 0 -1px var(--color-golden); */
}
.card__address {
    text-transform: uppercase;
    padding: 1rem;
    font-size: 20px;
    /* color: var(--color-black); */
    text-align: justify;
    position: absolute;
    top: 270px;
    bottom: 0;
    left: 0;
    right: 0;
    height: fit-content;
    margin: auto;
    /* text-shadow: -1px 0 var(--color-golden), 0 1px var(--color-golden), 1px 0 var(--color-golden), 0 -1px var(--color-golden); */
}

.card__description {
    padding: 1rem;
    padding: 1rem;
    font-size: 30px;
    color: white;
    text-align: center;
    position: absolute;
    /* top: 0; */
    bottom: 0;
    left: 0;
    right: 0;
    height: fit-content;
    margin: auto;
    /* text-shadow: -1px 0 var(--color-golden), 0 1px var(--color-golden), 1px 0 var(--color-golden), 0 -1px var(--color-golden); */
}