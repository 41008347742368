* {
  /* box-sizing: border-box; */
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
}

/* --------padding------ */
.section__padding {
  padding: 6rem 6rem;
}
.vertical__padding {
  padding: 4rem 0rem;
}
.vertical__margin {
  margin: 2rem 0rem;
}
.title__padding {
  padding: 1rem 0rem;
}

/* -------align-------- */
.flex__center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.center_align_text{
  /* Text-align will center an object within the box you create for it. 
  Try adding a margin that fills the page horizontally, and then centering the text within that.  */
  margin: 0 auto; 
  text-align: center;
}

.space-between {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.flex__column {
  display: flex;
  flex-direction: column;
}

.flex__row {
  display: flex;
  flex-direction: row;
  gap: 2rem;
}

/* font */
.p__dark {
  font-size: calc(8px + 1vh +1vw);
  color: var(--color-darkblue);
  font-family: var(--font-p);
}
.h__dark {
  font-family: var(--font-base);
  font-size: calc(14px + 1vh +1vw);
  color: var(--color-darkgreen);
}
.p__bright {
  font-size: calc(1px + 1vh +1vw);
  color: #FFF;
}
.h__bright {
  font-family: var(--font-base);
  font-size: calc(10px + 1vh +1vw);
  color: #FFF;
}


.home,
.services,
.products,
.sign-up {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.services {
  background-image: url('/images/img-2.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.products {
  background-image: url('/images/img-1.jpg');
  background-position: center;
  background-size: fill;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.sign-up {
  background-image: url('/images/img-8.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.fade-in {
  transition-delay: 1s;
  animation: fadeIn 4s;
  -webkit-animation: fadeIn 4s;
  -moz-animation: fadeIn 4s;
  -o-animation: fadeIn 4s;
  -ms-animation: fadeIn 4s;
}

.Youtube .video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.Youtube .video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@media screen and (max-width: 850px) {
  .flex__row{
      flex-direction: column;
  }
}