.footer-container {
  /* background-color: #242424; */
  /* background: linear-gradient(90deg, var(--color-green) 0%, rgb(109, 164, 226) 100%); */
  background-image: url('/images/FutunBG.jpg');
  background-size: cover;
  padding: 4rem 0 2rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* height:100vh; */
}

.footer-subscription {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  text-align: center;
  width: 100%;
  margin-bottom: 26px;
  padding: 26px;
  color: #fff;
}

.footer-subscription > p {
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
}

.footer-subscription-heading {
  margin-bottom: 26px;
  font-size: calc(20px + 1vh +1vw);
  text-transform: uppercase;
  letter-spacing: 3px;
}


.footer-subscription-text {
  margin-bottom: 28px;
  font-size: calc(15px + 1vh +1vw);
  text-transform: uppercase;
}

.footer-input {
  padding: 8px 20px;
  border-radius: 2px;
  margin-right: 10px;
  outline: none;
  border: none;
  font-size: calc(10px + 1vh +1vw);
  margin-bottom: 16px;
  border: 1px solid #fff;
}

.footer-links {
  width: 100%;
  max-width: 500px;
  display: flex;
  justify-content: center;
}

.footer-link-wrapper {
  display: flex;
}

.footer-link-items {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 16px;
  text-align: left;
  box-sizing: border-box;
}

.footer-link-items h2 {
  margin-bottom: 16px;
}

.footer-link-items > h2 {
  color: #fff;
}

.footer-link-items a {
  color: #fff;
  text-decoration: none;
  margin-bottom: 0.5rem;
}

.footer-link-items a:hover {
  color: #e9e9e9;
  transition: 0.3s ease-out;
}

.footer-email-form h2 {
  margin-bottom: 2rem;
}


.footer-rows{
  flex: 1;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    gap: 2rem;
}

.footer-input::placeholder {
  color: #b1b1b1;
}
.footer-logo img{
  width: 20vw;
}

/* Social Icons */
.social-icon-link {
  color: #fff;
  font-size: calc(10px + 1vh +1vw);
}

.social-media {
  max-width: 1000px;
  width: 100%;
}

.social-media-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  max-width: 1000px;
  margin: 40px auto 0 auto;
}

.social-icons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(5px + 1vh +1vw);
}

.social-logo {
  color: #fff;
  justify-self: start;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: calc(10px + 1vh +1vw);
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.website-rights {
  color: #fff;
  margin-bottom: 16px;
}

.info-wrap-contact_cell {
  position: relative;
  padding-left: 50px;
  /* font-size: 14px; */
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1.5px;
  margin-bottom: 21px;
  text-align: left;
}

.info-wrap-contact_cell i {
  position: absolute;
  left: 0;
  display: block;
  font-size: calc(10px + 1vh +1vw);
}


@media screen and (max-width: 820px) {
  .footer-links {
    padding-top: 2rem;
  }

  .footer-input {
    width: 100%;
  }

  .footer-link-wrapper {
    flex-direction: column;
  }

  .social-media-wrap {
    flex-direction: column;
  }
}

@media screen and (max-width: 768px) {
  .footer-rows{
    flex: 1;
      display: flex;
      justify-content: space-around;
      flex-direction: column;
      gap: 2rem;
  }
}
