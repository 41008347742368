.hero-container {
  /* background: url('/images/img-home.jpg') center center/cover no-repeat; */
  /* height: 100vh; */
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  box-shadow: inset 0 0 0 1000px rgba(51, 105, 109, 0.2);
  object-fit: contain;
  margin-top: -80px;
  height: 100vh;

  /* background: url("/images/2034.jpg");
  background-size: cover;
  background-position: 100% 50%; */
}

video {
  object-fit: cover;
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: -1;
}

.hero-container img {
  object-fit: contain;
  /* width: 100%; */
  height: 100vh;
  position: fixed;
  margin-left: auto;
  margin-right: auto;
  z-index: -1;
}

 #nav .hero-title h2{
  transform:translatey(-8px);
  margin-top:337px;
 }
  
 #nav .hero-title{
  padding-left:87px;
 }
 
 /* Hero btns */
 #nav .hero-btns{
  padding-left:78px;
 }


.hero-title > h1 {
  color: #fff;
  font-size: calc(25px + 1vh +1vw);
  font-family: var(--font-title);
  font-weight: normal;
  text-shadow: -2px 0 rgb(98, 139, 192), 0 2px rgb(112, 215, 233), 2px 0 rgb(104, 183, 236), 0 -2px rgb(29, 108, 139);
  /* margin-top: -100px; */
}

.hero-title > h2 {
  /* margin-bottom: 100px; */
  color: #fff;
  font-size: calc(40px + 1vh +1vw);
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
  text-transform: uppercase;
}

.hero-title > h3 {
  /* margin-bottom: 100px; */
  color: #fff;
  font-size: calc(20px + 1vh +1vw);
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
  text-transform: uppercase;
  letter-spacing: 3px;
}

.hero-title > p {
  color: #fff;
  font-size: px;
  /* margin-top: -100px; */
}

.hero-btns {
  margin-top: 32px;
}

.hero-btns .btn {
  margin: 6px;
}

.fa-play-circle {
  margin-left: 4px;
}

@media screen and (max-width: 960px) {
  .hero-container > h1 {
    font-size: 70px;
    margin-top: -150px;
  }
}

@media screen and (max-width: 768px) {
  .hero-container > h1 {
    font-size: 50px;
    margin-top: -100px;
  }

  .hero-container > p {
    font-size: 30px;
  }

  .btn-mobile {
    display: block;
    text-decoration: none;
  }

  
}
