@import url(https://fonts.googleapis.com/css2?family=Cormorant+Upright:wght@300;400;500;600;700&family=Open+Sans:wght@300;400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Cookie&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Arapey:ital@1&family=Cookie&family=Montserrat:wght@100&display=swap);
@import url(//fonts.googleapis.com/css?family=Montserrat:300,400,500,700);
/* At last you have to copy these css styles from here ,Create a CSS file and paste it and import the file */

/* Also you can customize these css styles*/

/* In my case, I use these CSS styles in my App.css file*/
.listing-section{
  background-color: var(--color-blue);
  padding: 2rem;
}
.listing .swiper {
    width: 80%;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  
  /* Swiper wrapper */
   .listing .mySwiper2{
    max-height:70vh;
   }

   .listing .mySwiper{
    min-height:10vh;
   }
   
   
  .listing .swiper-slide {
    background-position: center;
    background-size: cover;
    display:flex;
    justify-content: center;
  }
  
  .listing .swiper-slide img {
    display: cover;
    max-width: 100%;
    max-height: 70vh;
  }
:root {
  --font-base: 'Open Sans', serif;
  --font-title: 'Cookie', cursive;
  --font-alt: 'Open Sans', sans-serif;
  --font-p: 'Montserrat', sans-serif;;
  
  --color-golden: #DCCA87;
  --color-black: #044266;
  /* --color-black: #0b3d5a; */
  
  /* --color-golden: #f0d66e;
  --color-black: #2cb8ca; */

  --color-gray: #272b2b;
  --color-crimson: #f7e29f;
  --color-grey: #AAAAAA;
  --color-white: #FFFFFF;
  --color-green: rgb(115, 61, 117);
  --color-darkgreen: rgb(10, 38, 99);
  --color-blue: rgb(170, 205, 233);
  --color-darkblue:rgb(51, 78, 119);
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}


a {
  color: unset;
  text-decoration: none;
}

.slide-bottom {
  -webkit-animation: slide-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	animation: slide-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@-webkit-keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(-25%);
            transform: translateY(-25%);
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(-25%);
            transform: translateY(-25%);
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}


* {
  /* box-sizing: border-box; */
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
}

/* --------padding------ */
.section__padding {
  padding: 6rem 6rem;
}
.vertical__padding {
  padding: 4rem 0rem;
}
.vertical__margin {
  margin: 2rem 0rem;
}
.title__padding {
  padding: 1rem 0rem;
}

/* -------align-------- */
.flex__center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.center_align_text{
  /* Text-align will center an object within the box you create for it. 
  Try adding a margin that fills the page horizontally, and then centering the text within that.  */
  margin: 0 auto; 
  text-align: center;
}

.space-between {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.flex__column {
  display: flex;
  flex-direction: column;
}

.flex__row {
  display: flex;
  flex-direction: row;
  grid-gap: 2rem;
  gap: 2rem;
}

/* font */
.p__dark {
  font-size: calc(8px + 1vh +1vw);
  color: var(--color-darkblue);
  font-family: var(--font-p);
}
.h__dark {
  font-family: var(--font-base);
  font-size: calc(14px + 1vh +1vw);
  color: var(--color-darkgreen);
}
.p__bright {
  font-size: calc(1px + 1vh +1vw);
  color: #FFF;
}
.h__bright {
  font-family: var(--font-base);
  font-size: calc(10px + 1vh +1vw);
  color: #FFF;
}


.home,
.services,
.products,
.sign-up {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.services {
  background-image: url('/images/img-2.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.products {
  background-image: url('/images/img-1.jpg');
  background-position: center;
  background-size: fill;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.sign-up {
  background-image: url('/images/img-8.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.fade-in {
  transition-delay: 1s;
  animation: fadeIn 4s;
  -webkit-animation: fadeIn 4s;
  -moz-animation: fadeIn 4s;
  -o-animation: fadeIn 4s;
  -ms-animation: fadeIn 4s;
}

.Youtube .video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.Youtube .video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

@-webkit-keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@media screen and (max-width: 850px) {
  .flex__row{
      flex-direction: column;
  }
}
:root {
  --primary: #fff;
}

.btn {
  padding: 8px 20px;
  border-radius: 2px;
  outline: none;
  border: none;
  cursor: pointer;
}

.btn--primary {
  background-color: #fff;
  background-color: var(--primary);
  color: #242424;
  border: 1px solid #fff;
  border: 1px solid var(--primary);
}

.btn--outline {
  background-color: transparent;
  color: #fff;
  padding: 8px 20px;
  border: 1px solid #fff;
  border: 1px solid var(--primary);
  transition: all 0.3s ease-out;
}

.btn--medium {
  padding: 8px 20px;
  font-size: 18px;
}

.btn--large {
  padding: 12px 26px;
  font-size: 20px;
}

.btn--large:hover,
.btn--medium:hover {
  transition: all 0.3s ease-out;
  background: #fff;
  color: #242424;
  transition: 250ms;
}

.navbar {
  background: transparent;
  height: 80px;
  display: flex;
  justify-content: right;
  padding-right: 5vw;
  align-items: center;
  font-size: 1.2rem;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 999;
}

.navbar.active{
  /* background: linear-gradient(90deg, var(--color-green) 0%, rgb(109, 164, 226) 100%); */
  background-image: url('/images/FutunBG.jpg');
  position: -webkit-sticky;
  position: sticky;
}

.navbar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  max-width: 90vw;
}

.navbar-container h1 {
  color: #fff;
  font-size: 50px;
  font-family: var(--font-title);
  position: fixed;
  top: 0.5rem;
  left: 0.7rem;
  /* margin-top: -100px; */
}

.navbar-logo {
  color: #fff;
  justify-self: start;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
}

.fa-typo3 {
  margin-left: 0.5rem;
  font-size: 1.8rem;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(7, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width:55vw;
  justify-content: end;
  margin-right: 2rem;
}

.nav-item {
  height: 80px;
}

.nav-links {
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
}

.nav-links:hover {
  border-bottom: 4px solid #fff;
  transition: all 0.2s ease-out;
}

.fa-bars {
  color: #fff;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

@media screen and (max-width: 960px) {
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    /* background: linear-gradient(90deg, var(--color-green) 0%, rgb(109, 164, 226) 100%); */
    background-image: url('/images/FutunBG.jpg');
    background-size: cover;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    background-color: #fff;
    color: #242424;
    border-radius: 0;
  }

  
  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    -webkit-transform: translate(-100%, 60%);
            transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    text-decoration: none;
    font-size: 1.5rem;
    background-color: transparent;
    color: #fff;
    padding: 14px 20px;
    border: 1px solid #fff;
    transition: all 0.3s ease-out;
  }

  .nav-links-mobile:hover {
    background: #fff;
    color: #242424;
    transition: 250ms;
  }
}

.hero-container {
  /* background: url('/images/img-home.jpg') center center/cover no-repeat; */
  /* height: 100vh; */
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  box-shadow: inset 0 0 0 1000px rgba(51, 105, 109, 0.2);
  object-fit: contain;
  margin-top: -80px;
  height: 100vh;

  /* background: url("/images/2034.jpg");
  background-size: cover;
  background-position: 100% 50%; */
}

video {
  object-fit: cover;
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: -1;
}

.hero-container img {
  object-fit: contain;
  /* width: 100%; */
  height: 100vh;
  position: fixed;
  margin-left: auto;
  margin-right: auto;
  z-index: -1;
}

 #nav .hero-title h2{
  -webkit-transform:translatey(-8px);
          transform:translatey(-8px);
  margin-top:337px;
 }
  
 #nav .hero-title{
  padding-left:87px;
 }
 
 /* Hero btns */
 #nav .hero-btns{
  padding-left:78px;
 }


.hero-title > h1 {
  color: #fff;
  font-size: calc(25px + 1vh +1vw);
  font-family: var(--font-title);
  font-weight: normal;
  text-shadow: -2px 0 rgb(98, 139, 192), 0 2px rgb(112, 215, 233), 2px 0 rgb(104, 183, 236), 0 -2px rgb(29, 108, 139);
  /* margin-top: -100px; */
}

.hero-title > h2 {
  /* margin-bottom: 100px; */
  color: #fff;
  font-size: calc(40px + 1vh +1vw);
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
  text-transform: uppercase;
}

.hero-title > h3 {
  /* margin-bottom: 100px; */
  color: #fff;
  font-size: calc(20px + 1vh +1vw);
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
  text-transform: uppercase;
  letter-spacing: 3px;
}

.hero-title > p {
  color: #fff;
  font-size: px;
  /* margin-top: -100px; */
}

.hero-btns {
  margin-top: 32px;
}

.hero-btns .btn {
  margin: 6px;
}

.fa-play-circle {
  margin-left: 4px;
}

@media screen and (max-width: 960px) {
  .hero-container > h1 {
    font-size: 70px;
    margin-top: -150px;
  }
}

@media screen and (max-width: 768px) {
  .hero-container > h1 {
    font-size: 50px;
    margin-top: -100px;
  }

  .hero-container > p {
    font-size: 30px;
  }

  .btn-mobile {
    display: block;
    text-decoration: none;
  }

  
}

/* At last you have to copy these css styles from here ,Create a CSS file and paste it and import the file */

/* Also you can customize these css styles*/

/* In my case, I use these CSS styles in my App.css file*/

.listinghero .swiper-container {
  object-fit: contain; 
  width: 100%;
  height: 80vh;  
  /* position: absolute; */
  z-index: -1;
}
.listinghero .swiper {
    width: 80%;
    /* padding-top: 50px;
    padding-bottom: 50px; */

  }
  
  /* Swiper wrapper */
  

   .listinghero .mySwiper{
    min-height:10vh;
   }
   
   
  .listinghero .swiper-slide {
    background-position: center;
    background-size: cover;
    display:flex;
    justify-content: center;
  }
  
  .listinghero .swiper-slide img {
    display: cover;
    /* width: 100%; */
    height: 100vh;
  }
.app__about {
    position: relative;
    background-color: #FFF;
    /* height: 100vh; */
}

.app__about-overlay {
    position: absolute;
    inset: 0 0 20%;
}

.app__about-content {
    display: flex;
    width: 100%;
    z-index: 2;
    grid-gap: 2rem;
    gap: 2rem;
    /* height: 100%; */
}

.app__about-content_about {
    display: flex;

    flex: 1 1;

     /* The image used */
    background-image: url('/images/woman.jpg');

    /* Set a specific height */
    min-height: 50rem;
    min-width: 20rem;
    /* width: 50%; */

    /* Create the parallax scrolling effect */
    background-attachment: fixed;
    /* background-position: center; */
    background-repeat: no-repeat;
    background-size: contain;
}

.app__about-content_history {
    flex: 1 1;
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    flex-direction: column;

    text-align: left;
}

.app__about-content_history img{
    max-width: 40vw;
}

.app__about-content_about p,
.app__about-content_history p {
    margin: 2rem 0;
    color: var(--color-darkblue);
}

.app__about-highlight{
    flex-direction: column;
    height: 100%;
}

.app__about-highlight img {
    object-fit: fill;
}
.highlightList{
    display: grid;
    margin: 3rem;
    grid-template-columns: 5rem 5rem;
    grid-gap: 1rem;
    justify-content: space-around;
    text-align: center;
}

.highlightList_features{
    display: flexbox;
    margin: 3rem;
    grid-template-columns: 10rem 10rem 10rem 10rem;
    grid-gap: 1rem;
    justify-content: space-around;
    text-align: center;
}

.highlightList_features .CardItem {
    background-color: black;
}

.app__charac p{
    min-width: 20vw;
}

.app__features{
    flex-direction: column;
}

.Youtube{
    padding-top: 5rem;
    min-width: 80vw;
    justify-content: center;
}

.flex__center .vertical__margin{
    justify-content:space-between;
}
.self_align{
    align-self:center;
}
   
   /* Vertical  margin */
.app__about-highlight .flex__center .app__about-content_history .vertical__margin{
    width:100%;

}

   .app__charac__highlight{
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr))
   }

#characteristic div img{
overflow:auto;
min-width:50vw;
}


@media screen and (min-width: 2000px) {
    .app__about-content_about p,
    .app__about-content_history p {
        margin: 4rem 0;
    }
}

@media screen and (max-width: 900px) {
    .app__about-content {
        flex-direction: column;
    }

    .app__about-content_knife {
        margin: 4rem 0;
    }

    .app__about-highlight img {
        max-width: 90vw;
        object-fit: fill;
    }

    .app__charac{
        flex-direction: column;
        width: 100%;
    }
}

@media screen and (max-width: 650px) {
    .section__padding {
        padding: 2rem;
    }

}
.app__gallery {
    flex-direction: column;
    background: radial-gradient(at center center, #2d7e9270 0%, #1c40617c 100%);

    /* background: var(--color-black); */
    padding: 4rem 6rem 4rem 6rem;
}

.app__gallery-content {
    flex: 1 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;

    min-width: 500px;
    padding-bottom: 2rem;
}

.app__gallery-content button {
    margin-top: 1rem;
}

.app__gallery-images {
    flex: 1 1;
    display: flex;
    flex-direction: row;
    /* max-width: 50%; */
    position: relative;
    max-width: 100%;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    grid-column-gap: 1rem;
    -webkit-column-gap: 1rem;
            column-gap: 1rem;
    grid-row-gap: 1rem;
    row-gap: 1rem;
}

.app__gallery-images_container {
    display: flex;
    flex-direction: row;
    width: -webkit-max-content;
    width: max-content;
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;  
}

.app__gallery-images_container::-webkit-scrollbar {
    display: none;
}

.app__gallery-images_card {
    position: relative;
    min-width: 301px;
    height: 447px;
    margin-right: 2rem;
}

.gallery__image-icon {
    position: absolute;
    color: #fff;
    font-size: 2rem;
    opacity: 0;
    transition: .5s ease;
    cursor: pointer;
}

.app__gallery-images_card img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 1;
    transition: .5s ease;
}

.app__gallery-images_card:hover img {
    opacity: .35;
}

.app__gallery-images_arrows {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 0 1rem;
    position: absolute;
    bottom: 5%;
}

.gallery__arrow-icon {
    color: var(--color-golden);
    font-size: 2rem;
    cursor: pointer;
    background: var(--color-black);
    border-radius: 5px;
}

@media screen and (min-width: 2000px) {
    .app__gallery-content button {
        margin-top: 2rem;
    }

    .app__gallery-content {
        min-width: 1000px;
        padding-right: 4rem;
    }

    .app__gallery-images_card {
        min-width: 400px;
        height: 547px;
    }
}

@media screen and (max-width: 1150px) {
    .app__gallery {
        flex-direction: column;
    }

    .app__gallery-images {
        max-width: 100%;
        margin: 5rem 0;
    }
}

@media screen and (max-width: 850px) {
    .app__gallery {
        padding: 4rem 0 4rem 4rem;
    }
    .app__gallery-images {
        flex-direction: column;
    }
}

@media screen and (max-width: 650px) {
    .app__gallery {
        padding: 4rem 0 4rem 2rem;
    }  
    
    .app__gallery-content {
        min-width: 100%;
    }

    .app__gallery-images_card {
        min-width: 240px;
        height: 320px;
    }
}
.menuitem {
    overflow: hidden;
    box-shadow: 0px 2px 5px rgb(155, 155, 155);
    /* border-radius: 25px; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
    transition: -webkit-transform 200ms ease-in;
    transition: transform 200ms ease-in;
    transition: transform 200ms ease-in, -webkit-transform 200ms ease-in;
    height: 30vh;
    max-width: 50vh;
    min-width: 30vw;
    /* z-index: 1; */
    position: relative;
    margin: 0.1rem;
    /* background: radial-gradient(at center center, #2d7e9270 0%, #1c40617c 100%); */
}
.card:hover {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
    transition: -webkit-transform 100ms ease-in;
    transition: transform 100ms ease-in;
    transition: transform 100ms ease-in, -webkit-transform 100ms ease-in;
} 
.menuitem__img {
    display: flex;
    flex: 1 1;
    /* background: radial-gradient(at center center, #2d7e9270 0%, #1c40617c 100%); */
    height: 100%;
    width: 45vw;
    object-fit: cover;
    /* z-index: -1; */
    /* opacity: .8; */
    position: absolute;
    top: -9999px;
    bottom: -9999px;
    left: -9999px;
    right: -9999px;
    margin: auto;
}
.menuitem__img:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    transition: all 0.2s linear;

}
  
.card__propertyType {
    text-transform: uppercase;
    padding: 1rem;
    /* font-size: 30px; */
    color: white;
    text-align: justify;
    position: absolute;
    top: 0;
    bottom: 1;
    left: 0;
    right: 0;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin: auto;
    /* text-shadow: -1px 0 var(--color-golden), 0 1px var(--color-golden), 1px 0 var(--color-golden), 0 -1px var(--color-golden); */
}
.card__city {
    text-transform: uppercase;
    padding: 1rem;
    font-size: 2rem;
    /* color: var(--color-black); */
    text-align: center;
    position: absolute;
    top: 20vh;
    bottom: 0;
    left: 0;
    right: 0;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin: auto;
    /* text-shadow: -1px 0 var(--color-golden), 0 1px var(--color-golden), 1px 0 var(--color-golden), 0 -1px var(--color-golden); */
}
.card__address {
    text-transform: uppercase;
    padding: 1rem;
    font-size: 20px;
    /* color: var(--color-black); */
    text-align: justify;
    position: absolute;
    top: 270px;
    bottom: 0;
    left: 0;
    right: 0;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin: auto;
    /* text-shadow: -1px 0 var(--color-golden), 0 1px var(--color-golden), 1px 0 var(--color-golden), 0 -1px var(--color-golden); */
}

.card__description {
    padding: 1rem;
    padding: 1rem;
    font-size: 30px;
    color: white;
    text-align: center;
    position: absolute;
    /* top: 0; */
    bottom: 0;
    left: 0;
    right: 0;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin: auto;
    /* text-shadow: -1px 0 var(--color-golden), 0 1px var(--color-golden), 1px 0 var(--color-golden), 0 -1px var(--color-golden); */
}
/* At last you have to copy these css styles from here ,Create a CSS file and paste it and import the file */

/* Also you can customize these css styles*/

/* In my case, I use these CSS styles in my App.css file*/
.floorplan .swiper {
    max-height: 100%;
    min-width: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  
  /* Swiper wrapper */
.floorplan .mySwiper2{
    max-height:70vh;
   }

.floorplan .mySwiper{
    min-height:10vh;
   }
   
.floorplan .swiper-slide {
    background-position: center;
    background-size: cover;
    display:flex;
    justify-content: center;
  }

  .swiper .swiper-slide {
    background-position: center;
    background-size: cover;
    display:flex;
    justify-content: center;
    height: 20vh;
  }

.floorplan .swiper-slide img {
    max-height: 70vh;
    display: cover;
    /* width: 100%; */
  }

  .floorplan >h1{
    color: white;
  }

  .swiper .swiper-slide img {
    cursor: pointer;
    display: fill;
    overflow: hidden;
    object-fit: cover;
  }
.testimonial10 {
  font-family: "Montserrat", sans-serif;
  color: #8d97ad;
  font-weight: 300;
}

.testimonial10 h1,
.testimonial10 h2,
.testimonial10 h3,
.testimonial10 h4,
.testimonial10 h5,
.testimonial10 h6 {
  color: #3e4555;
  text-align: center;
}

.testimonial10 .font-weight-medium {
  font-weight: 500;
}

.testimonial10 .subtitle {
  color: #8d97ad;
  line-height: 24px;
}

.testimonial10 .testi10 .quote-bg {
  max-width: 800px;
  width: 100%;
  margin: 0 auto;
  padding: 60px;
  background: url(https://www.wrappixel.com/demos/ui-kit/wrapkit/assets/images/testimonial/up-quote.png) no-repeat top left, url(https://www.wrappixel.com/demos/ui-kit/wrapkit/assets/images/testimonial/down-quote.png) no-repeat bottom right;
}

.testimonial10 .testi10 h3 {
  line-height: 40px;
    font-size: 24px;
}

.testimonial10 .testi10 .customer-thumb img {
    width: 60px;
	margin-left: auto;
	margin-right: auto;
}

@media (max-width: 767px) {
  .testimonial10 .testi10 h3 {
    line-height: 30px;
    font-size: 20px;
  }
}

.testimonial10.bg-success-gradiant {
    /* background: #ffffff; */
    /* background: -webkit-linear-gradient(legacy-direction(to right), #2cdd9b 0%, #1dc8cc 100%); */
    /* background: -webkit-gradient(linear, left top, right top, from(#2cdd9b), to(#1dc8cc)); */
    /* background: -webkit-linear-gradient(left, #8cc5df 0%, #bc9de0 100%); */
    /* background: -o-linear-gradient(left, #367a61 0%, #1dc8cc 100%); */
    background: linear-gradient(to top, var(--color-blue) 0%,  #ffffff 100%);
}
.footer-container {
  /* background-color: #242424; */
  /* background: linear-gradient(90deg, var(--color-green) 0%, rgb(109, 164, 226) 100%); */
  background-image: url('/images/FutunBG.jpg');
  background-size: cover;
  padding: 4rem 0 2rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* height:100vh; */
}

.footer-subscription {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  text-align: center;
  width: 100%;
  margin-bottom: 26px;
  padding: 26px;
  color: #fff;
}

.footer-subscription > p {
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
}

.footer-subscription-heading {
  margin-bottom: 26px;
  font-size: calc(20px + 1vh +1vw);
  text-transform: uppercase;
  letter-spacing: 3px;
}


.footer-subscription-text {
  margin-bottom: 28px;
  font-size: calc(15px + 1vh +1vw);
  text-transform: uppercase;
}

.footer-input {
  padding: 8px 20px;
  border-radius: 2px;
  margin-right: 10px;
  outline: none;
  border: none;
  font-size: calc(10px + 1vh +1vw);
  margin-bottom: 16px;
  border: 1px solid #fff;
}

.footer-links {
  width: 100%;
  max-width: 500px;
  display: flex;
  justify-content: center;
}

.footer-link-wrapper {
  display: flex;
}

.footer-link-items {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 16px;
  text-align: left;
  box-sizing: border-box;
}

.footer-link-items h2 {
  margin-bottom: 16px;
}

.footer-link-items > h2 {
  color: #fff;
}

.footer-link-items a {
  color: #fff;
  text-decoration: none;
  margin-bottom: 0.5rem;
}

.footer-link-items a:hover {
  color: #e9e9e9;
  transition: 0.3s ease-out;
}

.footer-email-form h2 {
  margin-bottom: 2rem;
}


.footer-rows{
  flex: 1 1;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    grid-gap: 2rem;
    gap: 2rem;
}

.footer-input::-webkit-input-placeholder {
  color: #b1b1b1;
}

.footer-input:-ms-input-placeholder {
  color: #b1b1b1;
}

.footer-input::placeholder {
  color: #b1b1b1;
}
.footer-logo img{
  width: 20vw;
}

/* Social Icons */
.social-icon-link {
  color: #fff;
  font-size: calc(10px + 1vh +1vw);
}

.social-media {
  max-width: 1000px;
  width: 100%;
}

.social-media-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  max-width: 1000px;
  margin: 40px auto 0 auto;
}

.social-icons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(5px + 1vh +1vw);
}

.social-logo {
  color: #fff;
  justify-self: start;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: calc(10px + 1vh +1vw);
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.website-rights {
  color: #fff;
  margin-bottom: 16px;
}

.info-wrap-contact_cell {
  position: relative;
  padding-left: 50px;
  /* font-size: 14px; */
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1.5px;
  margin-bottom: 21px;
  text-align: left;
}

.info-wrap-contact_cell i {
  position: absolute;
  left: 0;
  display: block;
  font-size: calc(10px + 1vh +1vw);
}


@media screen and (max-width: 820px) {
  .footer-links {
    padding-top: 2rem;
  }

  .footer-input {
    width: 100%;
  }

  .footer-link-wrapper {
    flex-direction: column;
  }

  .social-media-wrap {
    flex-direction: column;
  }
}

@media screen and (max-width: 768px) {
  .footer-rows{
    flex: 1 1;
      display: flex;
      justify-content: space-around;
      flex-direction: column;
      grid-gap: 2rem;
      gap: 2rem;
  }
}

